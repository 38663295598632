// Fonts
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@400;600&family=Rubik&display=swap');
$dosis: 'Dosis',
sans-serif;
$rubik: 'Rubik',
sans-serif;

// Colors
$blue: #315ED1;
$white: #FFF;
$light: #E5E5E5;
$grey: #ACA7AB;
$black: #2F242C;

$standardTransition: 0.25s ease-out;

.ft-dosis {
    font-family: $dosis;
}

.ft-rubik {
    font-family: $rubik;
}

.fw-bold {
    font-weight: 600;
}

.text-blue {
    color: $blue !important;
}

.text-light {
    color: $light !important;
}

.text-grey {
    color: $grey !important;
}

.text-black {
    color: $black !important;
}

.bg-blue {
    background-color: $blue !important;
}

.bg-light {
    background-color: $light !important;
}

.bg-grey {
    background-color: $grey !important;
}

.bg-black {
    background-color: $black !important;
}

@mixin mobile {
    @media only screen and (max-width: 767px) {
        @content;
    }
}

@mixin desktop {
    @media only screen and (min-width: 768px) {
        @content;
    }
}

a {
    color: $blue;
    transition: $standardTransition;

    &:hover {
        text-decoration: none;
        color: darken($color: $blue, $amount: 25%);
    }
}

html,
body,
#root {
    height: 100%;
}

body,
#root {
    display: flex;
    flex-direction: column;
    font-family: $rubik;
    color: $black;
}

#root {
    overflow-x: hidden;
}

.page-wrapper {
    flex: 1 0 auto;
}

footer {
    flex-shrink: 0;
}

.main-nav {
    margin-top: 2rem;

    .brand {
        font-size: 20px;
        line-height: 36px;
        color: $blue;

        @include desktop {
            font-size: 30px;
        }

        img {
            width: 40px;
            margin-right: 1rem;

            @include desktop {
                width: 56px;
            }
        }
    }
}

.language-select {
    border: 0;
    outline: 0;
    padding: 12px;
    color: $grey;

    &:focus {
        border: 0;
        outline: 0;
    }
}

footer {
    text-align: center;
    padding-top: 56px;
    padding-bottom: 56px;

    * {
        color: $light;
    }
}

.welcome-text {
    margin-top: 72px;
    line-height: 81px;
    font-size: 64px;
    font-family: $dosis;
    font-weight: 600;
}

.text-signin {
    margin-top: 72px;
    line-height: 81px;
    font-size: 64px;
    font-family: $dosis;
    font-weight: 600;
}

.register-text {
    margin-top: 20px;
    font-family: $dosis;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;

    @include desktop() {
        font-size: 36px;
        line-height: 48px;
    }
}

.landing-button {
    max-width: 384px;
    width: 98%;
    background: $white;
    border: 1px solid $blue;
    box-sizing: border-box;
    border-radius: 8px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    line-height: 36px;
    font-family: $dosis;
    box-shadow: 0 8px 16px rgba($blue, 0.2);
    transition: $standardTransition;
    padding: 6px;
    font-size: 24px;
    color: $blue;

    @include desktop {
        font-size: 36px;
        padding: 22px;
    }

    &:hover {
        background-color: darken($color: $white, $amount: 5%);
    }

    &.has-bg {
        background-color: $blue;
        background-image: linear-gradient(180deg, #3B71DC 0%, #315ED1 100%);
        color: $white;

        &:hover {
            background-color: darken($color: $blue, $amount: 25%);
        }
    }
}

.form-field {
    max-width: 384px;
    width: 98%;

    &.is-search {
        max-width: unset;
        width: 100%;
        margin-left: auto;
        display: flex;
        margin-bottom: 2rem;

        @include desktop() {
            max-width: 208px;
        }
    }
}

.dot {
    width: 24px;
    height: 24px;
    border: 1px solid $blue;
    display: inline-flex;
    border-radius: 100%;
    align-items: center;
    justify-content: center;
    color: $blue;

    &:last-of-type {
        margin-left: 1em;
    }
}

.dot_selected {
    width: 24px;
    height: 24px;
    display: inline-flex;
    border-radius: 100%;
    align-items: center;
    justify-content: center;
    color: $white;
    background-color: $blue;

    &:last-of-type {
        margin-left: 1em;
    }
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA4IDgiPjxwYXRoIGZpbGw9IiMzMTVFRDEiIGQ9Ik02LjU2NC43NWwtMy41OSAzLjYxMi0xLjUzOC0xLjU1TDAgNC4yNiAyLjk3NCA3LjI1IDggMi4xOTN6Ii8+PC9zdmc+");
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: $grey;
    background-color: $white;
}

.guide-links {
    .pdf-placeholder {
        width: 56px;
        height: 56px;
        border: 1px solid #315ED1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        border-radius: 8px;
    }

    .guide-item-title {
        font-size: 18px;
        line-height: 24px;
        color: $black;
    }

    .guide-item-date {
        font-size: 14px;
        line-height: 24px;
        color: $grey;
    }
}

.sidebar-wrapper {
    position: relative;
    z-index: 1;

    @include desktop {
        position: absolute;
    }

}

#sidebar-menu {
    margin-top: 13px;
    border-bottom: 1px solid #E5E5E5;

    @include desktop {
        margin-top: 120px;
        border-bottom: 0;
    }

    .normal {
        color: $black;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        font-family: $dosis;

        &.selected {
            color: $blue;
            border-bottom: 2px solid $blue;
            padding-bottom: 4px;

            @include desktop() {
                border-bottom: 0;
            }
        }

        @include desktop() {
            font-size: 30px;
            line-height: 40px;
        }
    }

    .navbar-text {
        width: 100%;
        padding-bottom: 0;
    }

    .nav-link {
        display: inline-block;
        padding-bottom: 0px;

        @include desktop() {
            display: block;
        }
    }
}

#homeContainer {
    margin-top: 28px;

    @include desktop() {
        margin-top: 96px;
    }
}

#answersTableContainer {
    table {
        thead {
            background-color: $blue;
            color: $white;
        }

        @include mobile {
            font-size: 13px;
        }
    }

    .buttons-excel {
        max-width: 384px;
        width: 98%;
        background: $white;
        border: 1px solid $blue;
        box-sizing: border-box;
        border-radius: 8px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        line-height: 36px;
        font-family: $dosis;
        box-shadow: 0 8px 16px rgba($blue, 0.2);
        transition: $standardTransition;
        padding: 6px;
        font-size: 16px;
        background-color: $blue;
        background-image: linear-gradient(180deg, #3B71DC 0%, #315ED1 100%);
        color: $white;

        @include desktop {
            padding: 11px;
            font-size: 24px;
        }

        &:hover {
            background-color: darken($color: $blue, $amount: 25%);
        }
    }

    .paginate_button {
        &.previous {
            margin-left: auto;
        }

        .page-link {
            padding: 6px;
            line-height: 36px;
            font-family: $dosis;
            font-size: 16px;

            @include desktop {
                padding: 11px;
                font-size: 24px;
            }
        }
    }
}





body {
    font-family: Helvetica, sans-serif;
}
.App {
    position: absolute;
    top: 0; left: 0; bottom: 0; right: 0;
    color: whitesmoke;
}

.App .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* height: 5em; */
    z-index: 2;
    background-color: rgba(3, 14, 31, 0);
    padding: 0 25px;
}
.App .header h2 { font-family: monospace; font-size: 25px; }



.primary-btn {
    background-color: rgb(16, 96, 172);
    border: 1px solid rgb(16, 96, 172);
    padding: 24px 40px;
    font-size: 24px;
    color: whitesmoke;
    cursor: pointer;
    margin: 14px;
    border-radius: 5px;
    transition: all .2s;
}

.primary-btn:hover {
    background-color: rgb(15, 83, 146);
}

.primary-btn:active {
    background-color: rgb(11, 61, 107);
}

.secondary-btn {
    background-color: #787878;
    border: 1px solid rgb(7, 127, 131);
    padding: 16px 28px;
    font-size: 16px;
    color: whitesmoke;
    cursor: pointer;
    margin: 8px;
    border-radius: 5px;
    transition: all .2s;
}

.secondary-btn:hover {
    background-color: rgb(6, 96, 99);
}

.secondary-btn:active {
    background-color: rgb(3, 61, 63);
}

.next-slide-btn, .prev-slide-btn {
    background-color: rgba(208, 217, 235, 0.623);
    padding: 12px;
    border: 1px solid rgb(95, 95, 95);
    box-shadow: 0 0 5px 5px rgba(2, 5, 10, 0.445);
    border-radius: 50%;
    margin: 5px;
    cursor: pointer;
    transition: all .1s;
}

.next-slide-btn:hover, .prev-slide-btn:hover {
    background-color: rgba(208, 217, 235, 0.767);
}

.next-slide-btn:active, .prev-slide-btn:active {
    background-color: rgba(208, 217, 235, 0.856);
    box-shadow: none;
}

.next-slide-btn svg, .prev-slide-btn svg{
    width: 32px;
    height: 32px;
    fill: whitesmoke;
}

.login-btn {
    background-color: #EF6C00;
    border: 1px solid #FF8F00;
    padding: 5px 35px;
    font-size: 20px;
    color: whitesmoke;
    cursor: pointer;
    margin: 10px;
    border-radius: 25px;
    box-shadow: 0 0 2px 1px rgba(239, 108, 0, 0.795);
    transition: all .3s;
}

.login-btn:hover {
    background-color: #c75a02;
}

.login-btn:active {
    background-color: #ac5005;
}

.mobile-menu-container {
    display: block;
    background-color: white;
    z-index: 100;
    position: absolute;
    top: 80px; right: 0;
    width: 250px;
}

.mobile-menu-container .menu-item {
    color: black;
    padding: 15px;
    text-align: left;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
    transition: all .3s;
}

.mobile-menu-container .menu-item:active {
    background-color: #ccc;
}


.mobile-menu {
    display: none;
}
.desktop-menu {
    display: flex;
    align-items: center;
}
.brand {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.brand img {
    width: 96px;
}


.footer {
    z-index: 6;
    padding-bottom: 10px;
}

.footer .privacy-policy {
    font-size: 16px;
    margin-bottom: 10px;
    color: #6b97e7;
    cursor: pointer;
    text-decoration: underline;
    display: inline-block;
    font-weight: bold;
}


@media screen and (max-height:843px) {
    .primary-btn {
        padding-top: 15px;
    }

    .secondary-btn {
        padding-top: 15px;
    }
}


@media screen and (max-width:644px) {
    .primary-btn {
        font-size: 17px;
        padding: 8px 35px;
    }

    .secondary-btn {
        font-size: 17px;
        padding: 8px 35px;
    }

    .mobile-menu {
        display: block;
    }
    .desktop-menu {
        display: none;
    }

    .brand {
        display: flex;
        align-items: center;
    }

    .brand h2 {
        font-size: 20px !important;
    }

    .brand img {
        width: 64px;
    }
}