body,
html {
	overflow-x: hidden;
}

.Slide {
	width: 100%;
	height: 100%;
	text-align: center;
	background: url(../LandingPage/assets/image-background.jpg);
	background-size: cover;
	background-position: center;
	display: flex;
	flex-direction: column;
	display: none;
	position: relative;
}

.Slide::after {
	content: "";
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.384);
	z-index: 0;
}

.Slide.active {
	display: flex;
}

.Slide .SlideContent {
	flex: 1;
}

.Slide.SupportSlide .SlideContent {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	z-index: 5;
}

.Slide.SupportSlide .SlideContent .content {
	display: flex;
	padding: 50px;
	background-color: rgba(3, 14, 31, 0.356);
}

.Slide.SupportSlide .SlideContent .content .support-info-panel {
	background-color: rgba(3, 14, 31, 0.534);
	padding: 50px;
	height: 400px;
	margin: auto 20px auto 0;
	max-width: 500px;
}

.support-info-panel .header {
	font-size: 30px;
	display: flex;
	justify-content: center;
	border-bottom: 1px solid rgba(255, 255, 255, 0.548);
	padding-bottom: 20px;
}

.support-info-panel .msg-1 {
	max-width: 400px;
	font-size: 18px;
	margin-bottom: 10px;
}

.Slide.SupportSlide .SlideContent .content .form-container {
	padding: 40px;
	max-width:800px;
	margin-left:15px;
	margin-top:15px;
}

.form-container .form-field {
	margin: 10px 0;
}

.form-container .form-field-group {
	width: 100%;
	display: flex;
}

.form-container .form-field input,
.form-container .form-field textarea {
	border: 3px solid rgb(3, 14, 31);
	padding: 7px 10px;
	border-radius: 3px;
	width: 100%;
	outline: none;
	font-size: 16px;
}

.form-container .form-field input:focus,
.form-container .form-field textarea:focus {
	border: 3px solid rgb(84, 138, 219);
}

.form-container .form-field .label {
	font-weight: bold;
	font-size: 13px;
	display: inline-block;
	text-align: left;
	width: 100%;
	margin-left: 5px;
	color: rgb(190, 213, 255);
}

.form-container .send-container {
	display: flex;
	align-items: center;
	margin-top: 10px;
	width: 100%;
}

.form-container .recaptcha-container .recaptcha-error,
.form-container .terms-error {
	color: white;
	background-color: whitesmoke;
	border-radius: 3px;
	width: 100%;
	background-color: rgb(180, 34, 34);
	margin-bottom: 2px;
	padding: 10px 2px;
}

.form-container .send-action {
	flex: 1;
}

.aggreement-container {
	max-width: 100%;
	padding: 10px;
	background-color: rgba(3, 14, 31, 0.637);
}

.aggreement-container .text {
	margin-left: 10px;
}

@media screen and (max-width: 1512px) {
	.Slide.SupportSlide .SlideContent .content {
		padding: 20px 40px;
	}

	.Slide.SupportSlide .SlideContent .content .support-info-panel {
		padding: 30px 40px;
		height: 300px;
		margin: auto 20px auto 0;
	}

	.support-info-panel .header {
		font-size: 30px;
		padding-bottom: 20px;
	}

	.support-info-panel .msg-1 {
		max-width: 400px;
		font-size: 18px;
		margin-bottom: 10px;
	}

	.Slide.SupportSlide .SlideContent .content .form-container {
		padding: 5px;
	}

	.form-container .form-field {
		margin: 5px 0;
	}

	.form-container .form-field-group {
		width: 100%;
	}
}

@media screen and (max-width: 1130px) {
	.Slide.SupportSlide {
		height: auto;
	}

	.Slide.SupportSlide .SlideContent .content {
		display: block;
	}
}

@media screen and (max-width: 644px) {
	.Slide.SupportSlide {
		overflow-x: hidden;
	}

	.Slide.SupportSlide .SlideContent .content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.Slide.SupportSlide .SlideContent .content .support-info-panel {
		background-color: rgba(3, 14, 31, 0.534);
		padding: 10px 15px 35px 15px;
		margin: auto 0 auto 0;
	}

	.support-info-panel .header {
		font-size: 25px;
		display: flex;
		justify-content: center;
		border-bottom: 1px solid rgba(255, 255, 255, 0.548);
		padding-bottom: 20px;
	}

	.support-info-panel .msg-1 {
		font-size: 18px;
		margin-bottom: 10px;
	}

	.Slide.SupportSlide .SlideContent .content .form-container {
		padding-top: 20px;
	}

	.form-container .form-field {
		display: block;
		padding-top: 10px;
	}

	.form-container .form-field-group {
		width: 100%;
		display: block;
	}

	.form-container .form-field input,
	.form-container .form-field textarea {
		border: 3px solid rgb(3, 14, 31);
		padding: 10px 2px;
		border-radius: 3px;
		width: 100%;
		outline: none;
		min-width: 20px;
	}

	.form-container .send-container {
		display: block;
		margin-top: 10px;
		width: 100%;
	}

	.form-container .send-container .recaptcha-container {
		width: 100%;
		display: flex;
		justify-content: center;
	}

	.form-container .send-action {
		display: block;
		width: 100%;
	}
}