body,
html {
    overflow-x: hidden;
}

.Slide {
    width: 100%;
    height: 100%;
    text-align: center;
    background: url(../assets/image-background.jpg);
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    display: none;
    position: relative;
}

.Slide::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.384);
    z-index: 0;
}

.Slide.active {
    display: flex;
}

.Slide .SlideContent {
    flex: 1;
    background-overflow: visible;
    background-position:center;
    background-size: 100% 100%;
}

/* HOME (FIRST) SLIDE STYLES */
.Slide.HomeSlide {
    background: url(../assets/image-background.jpg);
    background-size: cover;
    background-position: center;
    z-index: 5;
    background-attachment: fixed;
}

.Slide.HomeSlide .SlideContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 5;
}

.Slide.HomeSlide .content {
    width: 100%;
    height: 100%;
    position: relative;
    flex: 1;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.Slide.HomeSlide .content .hero-text {
    padding: 50px;
    background-color: rgba(3, 14, 31, 0.397);
    font-size: 30px;
    margin: 20px 20px;
}

/* HOME (SECOND) SLIDE STYLES */
.Slide.ObjectivesSlide {
    text-align: center;
    background: url(../assets/background2.jpg);
    background-size: cover;
    background-position: center;
    z-index: 5;
    padding-top: 100px;
}

.Slide.ObjectivesSlide h2 {
    font-size: 35px;
    text-transform: uppercase;
}

.Slide.ObjectivesSlide .SlideContent {
    z-index: 5;
}

.Slide.ObjectivesSlide .item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}

.Slide.ObjectivesSlide .item .description {
    display: inline-block;
    width: 800px;
    text-align: left;
    font-size: 20px;
    margin-left: 35px;
}

.Slide.ObjectivesSlide .solution {
    margin-top: 100px;
    padding: 50px;
    background-color: rgba(3, 14, 31, 0.356);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Slide.ObjectivesSlide .solution p {
    font-size: 20px;
}

.Slide.ObjectivesSlide .actions {
    display: inline-block;
}

/**/
.Slide.PricingSlide {
    text-align: center;
    background: url(../assets/background2.jpg);
    background-size: cover;
    background-position: center;
    z-index: 5;
    padding-top: 100px;
}

.Slide.PricingSlide h2 {
    text-transform: uppercase;
    font-size: 35px;
}

.Slide.PricingSlide .SlideContent {
    z-index: 5;
}

.Slide.PricingSlide .desc-container {
    padding: 50px;
    width: 800px;
    display: inline-block;
}

.Slide.PricingSlide .desc-container .paragh {
    margin-top: 20px;
    font-size: 20px;
}

.Slide.PricingSlide .desc-container .paragh .price {
    font-size: 40px;
    font-weight: bold;
    color: cornflowerblue;
    margin-right: 10px;
}

.SlideManager {
    position: relative;
    width: 100%;
    height: 100%;
}

.SlideManager .actions {
    position: fixed;
    bottom: 10px;
    left: 15px;
    z-index: 99;
}

/* SUPPORT SLIDE */
.Slide.SupportSlide {
    /*background: #5c492d;*/
}

.Slide.SupportSlide .SlideContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 5;
}

.Slide.SupportSlide .SlideContent .content {
    display: flex;
    padding: 50px;
    background-color: rgba(3, 14, 31, 0.356);
}

.Slide.SupportSlide .SlideContent .content .support-info-panel {
    background-color: rgba(3, 14, 31, 0.534);
    padding: 50px;
    height: 300px;
    margin: auto 20px auto 0;
}

.support-info-panel .header {
    font-size: 30px;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.548);
    padding-bottom: 20px;
}

.support-info-panel .msg-1 {
    max-width: 400px;
    font-size: 18px;
    margin-bottom: 10px;
}

.Slide.SupportSlide .SlideContent .content .form-container {
    padding: 40px;
}

.form-container .form-field {
    margin: 10px 0;
}

.form-container .form-field-group {
    width: 100%;
    display: flex;
}

.form-container .form-field input,
.form-container .form-field textarea {
    border: 3px solid rgb(3, 14, 31);
    padding: 7px 10px;
    border-radius: 3px;
    width: 100%;
    outline: none;
    font-size: 16px;
}

.form-container .form-field input:focus,
.form-container .form-field textarea:focus {
    border: 3px solid rgb(84, 138, 219);
}

.form-container .form-field .label {
    font-weight: bold;
    font-size: 13px;
    display: inline-block;
    text-align: left;
    width: 100%;
    margin-left: 5px;
    color: rgb(190, 213, 255);
}

.form-container .send-container {
    display: flex;
    align-items: center;
    margin-top: 10px;
    width: 100%;
}

.form-container .recaptcha-container .recaptcha-error,
.form-container .terms-error {
    color: white;
    background-color: whitesmoke;
    border-radius: 3px;
    width: 100%;
    background-color: rgb(180, 34, 34);
    margin-bottom: 2px;
    padding: 10px 2px;
}

.form-container .send-action {
    flex: 1;
}

.aggreement-container {
    max-width: 100%;
    padding: 10px;
    background-color: rgba(3, 14, 31, 0.637);
}

.aggreement-container .text {
    margin-left: 10px;
}

@media screen and (max-width: 1512px) {
    .Slide.SupportSlide .SlideContent .content {
        padding: 20px 40px;
    }

    .Slide.SupportSlide .SlideContent .content .support-info-panel {
        padding: 30px 40px;
        height: 300px;
        margin: auto 20px auto 0;
    }

    .support-info-panel .header {
        font-size: 30px;
        padding-bottom: 20px;
    }

    .support-info-panel .msg-1 {
        max-width: 400px;
        font-size: 18px;
        margin-bottom: 10px;
    }

    .Slide.SupportSlide .SlideContent .content .form-container {
        padding: 5px;
    }

    .form-container .form-field {
        margin: 5px 0;
    }

    .form-container .form-field-group {
        width: 100%;
    }
}

@media screen and (max-width: 1130px) {
    .Slide.SupportSlide {
        height: auto;
    }

    .Slide.SupportSlide .SlideContent .content {
        display: block;
    }
}

@media screen and (max-height: 843px) {
    .Slide.ObjectivesSlide .item {
        margin-bottom: 20px;
    }

    .Slide.ObjectivesSlide .item .description {
        display: inline-block;
        width: 700px;
        text-align: left;
        font-size: 17px;
    }

    .Slide.ObjectivesSlide .solution {
        margin-top: 0px;
        padding: 5px;
    }

    .Slide.ObjectivesSlide .solution p {
        font-size: 18px;
    }
}

@media screen and (max-width: 644px) {
    .Slide.HomeSlide .content .hero-text {
        padding: 20px;
        font-size: 18px;
        margin: 10px;
    }

    .Slide.ObjectivesSlide h2 {
        font-size: 25px;
    }

    .Slide.ObjectivesSlide {
        height: auto;
    }

    .Slide.ObjectivesSlide .item {
        margin-bottom: 20px;
        padding: 0 10px;
    }

    .Slide.ObjectivesSlide .item .description {
        display: inline-block;

        text-align: left;
        font-size: 20px;
        margin-left: 15px;
    }

    .Slide.ObjectivesSlide .solution {
        padding: 10px 10px 50px 10px;
        margin-bottom: 90px;
    }

    .Slide.ObjectivesSlide .solution p {
        font-size: 20px;
    }

    .Slide.PricingSlide h2 {
        text-transform: uppercase;
        font-size: 25px;
    }

    .Slide.PricingSlide .desc-container {
        padding: 10px;
        width: inherit;
        display: inline-block;
    }

    .Slide.PricingSlide .desc-container .paragh {
        margin-top: 15px;
        font-size: 18px;
    }

    .Slide.PricingSlide .desc-container .paragh .price {
        font-size: 30px;
        font-weight: bold;
        color: cornflowerblue;
        margin-right: 10px;
    }

    .Slide.SupportSlide {
        overflow-x: hidden;
    }

    .Slide.SupportSlide .SlideContent .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .Slide.SupportSlide .SlideContent .content .support-info-panel {
        background-color: rgba(3, 14, 31, 0.534);
        padding: 10px 15px 35px 15px;
        margin: auto 0 auto 0;
    }

    .support-info-panel .header {
        font-size: 25px;
        display: flex;
        justify-content: center;
        border-bottom: 1px solid rgba(255, 255, 255, 0.548);
        padding-bottom: 20px;
    }

    .support-info-panel .msg-1 {
        font-size: 18px;
        margin-bottom: 10px;
    }

    .Slide.SupportSlide .SlideContent .content .form-container {
        padding-top: 20px;
    }

    .form-container .form-field {
        display: block;
        padding-top: 10px;
    }

    .form-container .form-field-group {
        width: 100%;
        display: block;
    }

    .form-container .form-field input,
    .form-container .form-field textarea {
        border: 3px solid rgb(3, 14, 31);
        padding: 10px 2px;
        border-radius: 3px;
        width: 100%;
        outline: none;
    }

    .form-container .send-container {
        display: block;
        margin-top: 10px;
        width: 100%;
    }

    .form-container .send-container .recaptcha-container {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .form-container .send-action {
        display: block;
        width: 100%;
    }
}